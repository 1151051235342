function greet(whatToGreed) { alert(whatToGreed); }

function setActive(event) {
    clickedElement = event.target;
    var tabs = document.querySelectorAll(".tabs_wrap ul li");
    tabs.forEach((tab) => {
        if (tab == clickedElement) {
            tab.classList.add("active");
        } else {
            tab.classList.remove("active");
        }
    })
}
